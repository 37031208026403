import React from "react"
import LayoutCourse from "../../../../layouts/course"
import Seo from "../../../../components/seo"
import Heading from "../../../../components/heading"
import Stack from "../../../../components/stack"
import Box from "../../../../components/box"
import CourseFooterNext from "../../../../components/course-footer-next"
import Paragraph from "../../../../components/paragraph"
import { getCourseNavigation } from "../../../../store/courses"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          title="Bist du bereit?"
          label="Los Geht's"
          to="/kurse/versteckte-ordnung/02-ordnung/trichter"
        />
      }
      navigation={navigation}
    >
      <Seo title="Die versteckte Ordnung der Gedichte" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="span" color="whisper">
              Kapitel 2
            </Heading>
            <Heading as="h2" level={2}>
              Eine Frage der Ordnung
            </Heading>
          </Stack>
          <Paragraph>
            Gedichte haben meist eine ganz besondere Form. Sie spielen mit
            Zahlen, mit Klängen und mit Wiederholungen. Sie folgen einer
            bestimmten Melodie, einem Rhythmus oder einem Muster. Sie gehorchen
            einer versteckten Ordnung, die der Dichter beim Schreiben darin
            versteckt hat.
          </Paragraph>
          <Paragraph>
            Nach welchen Prinzipien sind die folgenden Gedichte geordnet?
            Erkennst du darin ein Muster? Bei vielen Beispielen sind auch
            mehrere Antworten möglich.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
